import { Form, Link, useActionData, useSearchParams } from '@remix-run/react'
import {
  redirect,
  json,
  type MetaFunction,
  LoaderFunction,
  ActionFunction,
} from '@remix-run/node'
import { Icon } from '@/components/ui/icon'
import { getUser, createUserSession } from '@/lib/session.server'
import { redirectSafely } from '@/lib/redirect-safely.server'
import { validateEmail } from '@/lib/validation.server'
import { workos, workosClientId } from '@/lib/workos.server'

export const loader: LoaderFunction = async ({ request }) => {
  const user = await getUser(request)
  if (user) return redirect('/capital')
  return null
}

interface ActionData {
  errors?: {
    email?: string
    password?: string
  }
}

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const redirectTo = redirectSafely(formData.get('redirectTo'), '/capital')
  const remember = formData.get('remember')
  const data = Object.fromEntries(formData)

  if (!validateEmail(data.email as string) || typeof data.email !== 'string') {
    return json<ActionData>(
      { errors: { email: 'Email is invalid' } },
      { status: 400 },
    )
  }

  if (typeof data.password !== 'string') {
    return json<ActionData>(
      { errors: { password: 'Password is required' } },
      { status: 400 },
    )
  }

  if (data.password.length < 8) {
    return json<ActionData>(
      { errors: { password: 'Password is too short' } },
      { status: 400 },
    )
  }

  // Send WorkOS API call to authenticate user
  let res
  try {
    res = await workos.userManagement.authenticateWithPassword({
      email: data.email,
      password: data.password,
      clientId: workosClientId,
    })
  } catch (error) {
    if (error instanceof Error && 'status' in error && error.status === 403) {
      return redirect('/auth/verify-email')
    }
    if (error instanceof Error && 'status' in error && error.status === 400) {
      return json<ActionData>(
        { errors: { email: 'Invalid email or password' } },
        { status: 400 },
      )
    }
    throw error
  }

  if (!res.user) {
    return json<ActionData>(
      { errors: { email: 'Invalid email or password' } },
      { status: 400 },
    )
  }

  // Create cookie user session
  return createUserSession({
    request,
    response: res,
    remember: remember === 'on',
    redirectTo,
  })
}

export const meta: MetaFunction = () => {
  return [
    {
      title: 'Login',
    },
  ]
}

export default function Login() {
  const actionData = useActionData<ActionData>()
  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo') || '/capital'

  return (
    <div className="flex min-h-full flex-1 flex-col sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <Icon name="joyst" className="h-48 w-48" />
        </div>
      </div>

      <div className="mb-8 mt-2 sm:mx-auto sm:w-full sm:max-w-[480px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <h2 className="mb-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Log in to your account
          </h2>
          <Form className="space-y-6" method="POST">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  aria-invalid={actionData?.errors?.email ? true : undefined}
                  aria-describedby="email-error"
                  className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                />
                {actionData?.errors?.email && (
                  <p className="pt-1 text-red-700" id="email-error">
                    {actionData.errors.email}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  aria-invalid={actionData?.errors?.password ? true : undefined}
                  aria-describedby="password-error"
                  className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
                />
                {actionData?.errors?.password && (
                  <div className="pt-1 text-red-700" id="password-error">
                    {actionData.errors.password}
                  </div>
                )}
              </div>
            </div>
            <input type="hidden" name="redirectTo" value={redirectTo} />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember"
                  name="remember"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600"
                />
                <label
                  htmlFor="remember"
                  className="ml-3 block text-sm leading-6 text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm leading-6">
                <Link
                  to={{
                    pathname: '/auth/forgot-password',
                    search: searchParams.toString(),
                  }}
                  className="font-semibold text-brand-600 hover:text-brand-500"
                >
                  Forgot password?
                </Link>
              </div>
            </div>

            <div>
              <button
                name="_action"
                value="login"
                type="submit"
                className="flex w-full justify-center rounded-md bg-brand-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
              >
                Sign in
              </button>
            </div>
          </Form>

          <div>
            <div className="relative mt-10">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-1 gap-4">
              <Link
                to={{
                  pathname: '/auth/google-oauth',
                  search: searchParams.toString(),
                }}
                className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              >
                <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                  <path
                    d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                    fill="#EA4335"
                  />
                  <path
                    d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                    fill="#4285F4"
                  />
                  <path
                    d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                    fill="#34A853"
                  />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </Link>
            </div>
          </div>
          <p className="mt-10 text-center text-sm text-gray-500">
            Don&apos;t have an account?{' '}
            <Link
              to={{
                pathname: '/auth/signup',
                search: searchParams.toString(),
              }}
              className="font-semibold leading-6 text-brand-600 hover:text-brand-500"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
